import React from "react";
import { useSelector } from "react-redux";
import { selectTitle } from "../../../redux/slices/title/titleSlice";
import "./PageTitle.css";

function PageTitle() {
  const title = useSelector(selectTitle);
  return (
    <>
      <h2>{title}</h2>
    </>
  );
}

export default PageTitle;
