import React from "react";
import Back from "../shared/buttons/Back";
import "./Index.css";

function PageNotFound({ history }) {
  const handleClick = () => {
    history.push("/");
  };

  return (
    <div className="content customer">
      <div className="inner-content">
        <h3>404 Not Found</h3>
        <Back content={"Go Home"} handleClick={handleClick} />
      </div>
    </div>
  );
}

export default PageNotFound;
