import React from "react";
import { Button, Modal } from "rsuite";
import "../return/Popup.css";

function ServiceOffer({ displayPopup, hidePopup, content, title }) {
  return (
    <div className="modal-container">
      <Modal
        size={"sm"}
        backdrop={true}
        open={displayPopup}
        show={displayPopup}
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={(e) => hidePopup(e)}
            appearance="primary"
            color="yellow"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ServiceOffer;
