import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  selectOrderNumber,
  selectEmail,
  selectIsUs,
} from "../../redux/slices/customer/customerSlice";
import {
  selectOrder,
  getItems,
  canOnlyReturnFaulty,
  hasComfortGurantee,
  selectReturns,
} from "../../redux/slices/order/orderSlice";
import {
  loadReasons,
  selectReasons,
} from "../../redux/slices/reason/reasonSlice";
import {
  getFamilyOptions,
  selectFamilyOptions,
  getColourOptions,
  selectColourOptions,
  selectSizeOptions,
  getSizeOptions,
  selectUsSizeOptions,
  getUsSizeOptions,
} from "../../redux/slices/option/optionSlice";
import {
  addItems,
  selectReturnItems,
} from "../../redux/slices/return/returnSlice";
import ItemList from "./ItemList";
import ServiceOffer from "./Popup";
import "./Index.css";
import { setStepStatus } from "../../redux/slices/step/stepSlice";
import CancelOrder from "../shared/buttons/CancelOrder";
import { ButtonToolbar } from "rsuite";
import ConfirmBtn from "./ConfirmBtn";
import { selectToken } from "../../redux/slices/auth/authSlice";
import { getCSRF } from "../../api/authApi";
import { useHistory } from "react-router-dom";
import { getProductItem } from "../../api/productApi";
import { toast } from "react-toastify";

function Return({ history }) {
  const orderNumber = useSelector(selectOrderNumber);
  const email = useSelector(selectEmail);
  const order = useSelector(selectOrder);
  const returnItems = useSelector(selectReturnItems);
  const reasons = useSelector(selectReasons);
  const familyOptions = useSelector(selectFamilyOptions);
  const colourOptions = useSelector(selectColourOptions);
  const sizeOptions = useSelector(selectSizeOptions);
  const usSizeOptions = useSelector(selectUsSizeOptions);
  const isUs = useSelector(selectIsUs);

  const onlyReturnFaulty = useSelector(canOnlyReturnFaulty);
  const comfortGuarantee = useSelector(hasComfortGurantee);
  const token = useSelector(selectToken);
  let hist = useHistory();

  const [shown, setShown] = useState(false);
  const [shownFit, setShownFit] = useState(false);
  const [displayFitPopup, setDisplayFitPopup] = useState(false);
  const [displayRefundPopup, setDisplayRefundPopup] = useState(false);
  const [itemTriggeringPopup, setItemTriggeringPopup] = useState("");
  const [defaultFileList, setDefaultFileList] = useState([]);

  const [selected, setSelected] = useState({});
  const [refundType, setRefundType] = useState({});
  const [extendedReasons, setExtendedReasons] = useState({});
  const [reason, setReason] = useState({});
  const [size, setSize] = useState({});
  const [colour, setColour] = useState({});
  const [errors] = useState({});
  const [product, setProduct] = useState([]);
  const [exchangeType, setExchangeType] = useState([]);

  const [searchEntry, setSearchEntry] = useState([]);

  const [csrf, setCsrf] = useState();
  const [qty, setQty] = useState([]);
  const [newValues, setNewValues] = useState([]);
  const [mainSku, setMainSku] = useState([]);

  const [appTime, setAppTime] = useState(false);

  const returnRecords = useSelector(selectReturns);
  const [refundNote, setRefundNote] = useState([]);
  const [usProductLabel, setUsProductLabel] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getCSRF().then((res) => {
      setCsrf(res.CSRFToken);
    });
    if (
      !usSizeOptions ||
      usSizeOptions === null ||
      Object.keys(usSizeOptions).length === 0
    ) {
      dispatch(getUsSizeOptions(token));
    }
    if (!order || order === null || Object.keys(order).length === 0) {
      dispatch(getItems(orderNumber, token, csrf));
    }
    if (!reasons || reasons === null || Object.keys(reasons).length === 0) {
      dispatch(loadReasons(token));
    }
    if (
      !familyOptions ||
      familyOptions === null ||
      Object.keys(familyOptions).length === 0
    ) {
      dispatch(getFamilyOptions(token));
    }
    if (
      !colourOptions ||
      colourOptions === null ||
      Object.keys(colourOptions).length === 0
    ) {
      dispatch(getColourOptions(token));
    }
    if (
      !sizeOptions ||
      sizeOptions === null ||
      Object.keys(sizeOptions).length === 0
    ) {
      dispatch(getSizeOptions(token));
    }

    console.log("order.items", order.items);
    setInitialStates();
  }, []);

  // useEffect(() => {
  //   getProduct();
  // }, []);
  useEffect(() => {
    console.log("familyOptions", familyOptions);
    console.log("colourOptions", colourOptions);
    console.log("sizeOptions", sizeOptions);
    console.log("USsizeOptions", usSizeOptions);
  }, [familyOptions, colourOptions, sizeOptions, usSizeOptions]);

  const getNewItemPrice = (sku, newValuesArray) => {
    // let sku = sku.split("-")[0];
    console.log("getNewItemPrice sku", sku);
    if (sku) {
      return getProductItem(sku.trim(), token).then((result) => {
        if (result && result.length > 0 && result[0].price) {
          console.log("new product or sku", result[0]);
          newValuesArray[sku] = result[0];
          setNewValues(newValuesArray);
          return result[0];
        } else {
          return null;
        }
      });
    }
  };

  const getProduct = async (sku) => {
    return getProductItem(sku.trim(), token).then((result) => {
      if (result && result.length > 0 && result[0].price) {
        console.log("product data", result[0]);
        return result[0];
      } else {
        return null;
      }
    });
  };

  const setInitialStates = () => {
    let selected = {};
    let refundType = {};
    let extendedReasons = {};
    let reason = {};
    let size = {};
    let colour = {};
    let exchangeType = {};
    let searchEntry = {};
    let product = {};
    let quantity = {};
    let mainsku = {};
    let refundNote = {};
    let usProductLabel = {};

    console.log("starting setting initial state", returnItems);
    returnItems.map((item) => {
      selected[item.itemId] = item.selected;
      refundType[item.itemId] = item.type;
      extendedReasons[item.itemId] = item.extendedReason;
      reason[item.itemId] = item.reason;
      size[item.itemId] = item.size;
      colour[item.itemId] = item.colour;
      exchangeType[item.itemId] = item.exchangeType;
      searchEntry[item.itemId] = item.searchEntry;
      product[item.itemId] = item.newProduct;
      usProductLabel[item.itemId] = item.newProductUs;
      quantity[item.itemId] = 1;
      refundNote[item.itemId] = "";
      mainsku[item.sku] = "";
    });
    console.log("end setting initial state", quantity);

    setSelected(selected);
    setRefundType(refundType);
    setExtendedReasons(extendedReasons);
    setReason(reason);
    setSize(size);
    setColour(colour);
    setSearchEntry(searchEntry);
    setExchangeType(exchangeType);
    setProduct(product);
    setQty(quantity);
    setMainSku(mainsku);
    setRefundNote(mainsku);
    setUsProductLabel(usProductLabel);
  };
  const onQtyChange = (value, event, item = []) => {
    if (value <= item.qty_ordered) {
      // if (value <= 5) {
      let newQty = Object.assign({}, qty);
      newQty[item.item_id] = parseInt(value);
      console.log("here asdasd", value);
      setQty(newQty);
    } else {
      console.log("asdasd 1", value);
      let newQty = Object.assign({}, qty);
      newQty[item.item_id] = 1;
      setQty(newQty);
      // alert("quantity should be less than or equal to ordered quantity");
    }
  };

  const onChange = (event, itemId, item = []) => {
    const { name, value, checked } = event.target;
    switch (name) {
      case "refundType":
        let newRefundType = Object.assign({}, refundType);
        console.log("value asd", value);
        if (value == "Refund") {
          console.log("returnRecords", returnRecords);
          if (returnRecords && returnRecords.length > 0) {
            let returnExistsExchange = [];
            returnExistsExchange = returnRecords.filter((returnRecord) => {
              if (returnRecord.type == "Exchange") {
                let skuToCheck = returnRecord.sku.trim();
                if (returnRecord.original_sku != "NA") {
                  skuToCheck = returnRecord.original_sku.trim();
                }
                return item.sku.trim() === skuToCheck;
              } else {
                return false;
              }
            });
            console.log("asdasd", returnExistsExchange);
            if (returnExistsExchange && returnExistsExchange.length > 0) {
              let msg = `As this is an exchanged item, please contact our Customer Care team on +44 (0) 808 178 2526 or at sales@strivefootwear.com who will be able to process a
                secondary refund. Our system is unable to do this at the moment. We apologise for the inconvenience`;
              newRefundType[itemId] = "Exchange";
              // newRefundType[itemId] = value;
              setRefundType(newRefundType);
              toast.error(msg, {
                closeButton: true, // or MyCustomCloseButton
                autoClose: 40000,
              });
              // if (returnExistsExchange[0].is_already_exchanged) {
              //   newRefundType[itemId] = "Exchange";
              //   setRefundType(newRefundType);
              //   toast.error(
              //     "Sorry, Refund is not available for already exchanged item. you can choose exchange only"
              //   );
              // }
            } else {
              newRefundType[itemId] = value;
              setRefundType(newRefundType);
            }
          } else {
            newRefundType[itemId] = value;
            setRefundType(newRefundType);
          }
        } else {
          newRefundType[itemId] = value;
          setRefundType(newRefundType);
        }
        // if (value === "Refund" && !onlyReturnFaulty) {
        //   setDisplayRefundPopup(true);
        //   setItemTriggeringPopup(itemId);
        // }
        break;
      case "reasons":
        let newReason = Object.assign({}, reason);
        newReason[itemId] = value;
        setReason(newReason);
        break;
      case "selected" + itemId:
        let newSelected = Object.assign({}, selected);
        newSelected[itemId] = checked;
        setSelected(newSelected);
        console.log(selected);
        break;
      case "extendedReason":
        let newExtendedReason = Object.assign({}, extendedReasons);
        newExtendedReason[itemId] = value;
        setExtendedReasons(newExtendedReason);
        if (
          (value === "1" || value === "2" || value === "3" || value === "4") &&
          refundType[itemId] === "Refund" &&
          !onlyReturnFaulty
        ) {
          setDisplayFitPopup(false);
          setItemTriggeringPopup(itemId);
        }
        break;
      case "size":
        console.log("size", size);
        let newSize = Object.assign({}, size);
        newSize[itemId] = value;
        setSize(newSize);
        setColour({});
        break;
      case "colour":
        let newColour = Object.assign({}, colour);
        newColour[itemId] = value;
        setColour(newColour);
        break;
      case "product":
        console.log("product", colour);
        // let newProduct = Object.assign({}, colour);
        let newProduct = Object.assign({}, product);
        newProduct[itemId] = value;
        var index = event.nativeEvent.target.selectedIndex;
        let text = event.nativeEvent.target[index].text;
        console.log("text", text);
        if (text.toLowerCase().includes("us")) {
          let newProductUs = Object.assign({}, usProductLabel);
          newProductUs[itemId] = text;
          setUsProductLabel(newProductUs);
        }
        setProduct(newProduct);

        break;
      case "product-search":
        console.log("searchEntry", searchEntry);
        let newSearchEntry = Object.assign({}, searchEntry);
        newSearchEntry[itemId] = value;
        setSearchEntry(newSearchEntry);
        break;
      case "exchange-type":
        let newExchangeType = Object.assign({}, exchangeType);
        newExchangeType[itemId] = value;
        setExchangeType(newExchangeType);
        break;
      case "qty_to_return":
        if (value == 0) {
          console.log("value 0", value);

          let newQty = Object.assign({}, qty);
          newQty[itemId] = 1;
          setQty(newQty);
        } else if (value <= item.qty_ordered) {
          let newQty = Object.assign({}, qty);
          newQty[itemId] = parseInt(value);
          console.log("here asdasd", value);
          setQty(newQty);
        } else {
          console.log("asdasd 1", value);
          let newQty = Object.assign({}, qty);
          newQty[itemId] = 1;
          setQty(newQty);
          // alert("quantity should be less than or equal to ordered quantity");
        }
        break;
      default:
        return null;
    }
  };

  const hideFitPopup = (e, status) => {
    if (status) {
      let newRefundType = Object.assign({}, exchangeType);
      newRefundType[itemTriggeringPopup] = "Exchange";
      setRefundType(newRefundType);
    }
    setShownFit(true);
    setDisplayFitPopup(false);
  };

  const hideRefundPopup = (e, status) => {
    if (status) {
      let newRefundType = Object.assign({}, exchangeType);
      newRefundType[itemTriggeringPopup] = "Exchange";
      setRefundType(newRefundType);
    }
    setShown(true);
    setDisplayRefundPopup(false);
  };

  const formatReason = (reasonId) => {
    let selectedReason = reasons.filter((reason) => {
      return parseInt(reason.Id) === parseInt(reasonId);
    });
    return selectedReason[0].label;
  };

  function getOption(item) {
    console.log("option item", item);
    let optionVal =
      item.parent_item.product_option.extension_attributes
        .configurable_item_options[0].option_value;
    // let optionValue = item.custom_attributes.find((obj) => {
    //   return obj.attribute_code === "us_size";
    // });
    return optionVal;
  }

  const onSave = () => {
    console.log("qty", qty);
    if (order && order.items) {
      let returnItems = [];
      order.items.map((item) => {
        if (item && item.product_type && item.product_type === "simple") {
          let itemId = item.item_id;

          if (selected[itemId]) {
            if (qty[itemId] == undefined) {
              qty[itemId] = 1;
            }
            console.log("mainSku", mainSku);
            let old_sku = item.sku;
            let old_name = item.name;
            let tax = item.hasOwnProperty("parent_item")
              ? item.parent_item.price_incl_tax - item.parent_item.price
              : item.price_incl_tax - item.price;
            let value = item.hasOwnProperty("parent_item")
              ? item.parent_item.price_incl_tax
              : item.price_incl_tax;
            let already_exchanged = 0;
            let option = 0;
            if (item.hasOwnProperty("parent_item")) {
              option = getOption(item);
            }
            if (newValues && newValues[item.sku] !== undefined) {
              console.log("newValues[item.sku]", newValues[item.sku]);
              old_sku = newValues[item.sku].sku;
              old_name = newValues[item.sku].name;
              value = newValues[item.sku].price;
              let store = item.store_id;
              if (store == 1) {
                store = "strive_uk";
              } else {
                store = "strive_eu";
              }
              tax = 0;
              already_exchanged = 1;
              if (old_sku.toLowerCase().includes("us")) {
                let optionVal = newValues[item.sku].custom_attributes.find(
                  (obj) => {
                    return obj.attribute_code === "us_size";
                  }
                );
                if (optionVal) {
                  option = optionVal.value;
                }
              }
            }
            let returnItem = {
              selected: selected[itemId],
              reason: reason[itemId],
              extendedReason: extendedReasons[itemId],
              reasonLabel: formatReason(reason[itemId]),
              size: size[itemId],
              colour: colour[itemId],
              type: refundType[itemId],
              currency: order.order_currency_code,
              country: order.billing_address.country_id,
              oldItem: {
                sku: old_sku,
                name: old_name,
                value: value,
                tax: tax,
                qty_to_return: qty[itemId],
                option: option,
              },
              itemId: itemId,
              newProduct: product[itemId],
              newProductUs: usProductLabel[itemId],
              exchangeType: exchangeType[itemId],
              searchEntry: searchEntry[itemId],
              comfort: comfortGuarantee ? 1 : 0,
              granted: parseInt(reason[itemId]) !== 1 ? 1 : 0,
              images: defaultFileList[itemId] ? defaultFileList[itemId] : null,
              order_id: item.order_id,
              parentItemId: item.parent_item_id,
              original_sku: item.sku,
              is_already_exchanged: already_exchanged,
            };
            returnItems.push(returnItem);
          }
        }
      });
      console.log("returnItems", returnItems);
      dispatch(addItems(returnItems));
      dispatch(setStepStatus(2));
    }

    history.push("/confirm");
  };

  return email !== null &&
    orderNumber !== null &&
    order &&
    order !== null &&
    order.items ? (
    <>
      <div className="content">
        <div className="inner-content">
          <h3>WHICH PRODUCTS WOULD YOU LIKE TO RETURN?</h3>
          {/* <p className="return-note">
            Please Note: We cannot guarantee the exchanged item you choose will
            be available once we receive your return
          </p> */}
          <div>
            <table className="item-return-table">
              {usSizeOptions && usSizeOptions.length > 0 ? (
                <ItemList
                  order={order}
                  onChange={onChange}
                  onQtyChange={onQtyChange}
                  selected={selected}
                  errors={errors}
                  refundType={refundType}
                  reason={reason}
                  extendedReason={extendedReasons}
                  size={size}
                  colour={colour}
                  colourOptions={colourOptions}
                  product={product}
                  exchangeType={exchangeType}
                  searchEntry={searchEntry}
                  defaultFileList={defaultFileList}
                  setDefaultFileList={setDefaultFileList}
                  qty={qty}
                  setNewValues={setNewValues}
                  newValues={newValues}
                  setMainSku={setMainSku}
                  mainSku={mainSku}
                ></ItemList>
              ) : null}
            </table>
          </div>
          <ButtonToolbar>
            <CancelOrder
              history={hist}
              direction={"/"}
              content={"Cancel Return"}
            />
            <ConfirmBtn
              onSave={onSave}
              selected={selected}
              refundType={refundType}
              extendedReasons={extendedReasons}
              reason={reason}
              size={size}
              colour={colour}
              exchangeType={exchangeType}
              product={product}
              order={order}
            ></ConfirmBtn>
          </ButtonToolbar>
          {/* <ServiceOffer
            displayPopup={displayRefundPopup}
            hidePopup={hideRefundPopup}
            content={
              <>
                <p>
                  Did you know, if your footwear doesn’t fit, you can exchange
                  them for a different size and enjoy FREE shipping and a FREE
                  returns label if the new size is still not suitable.
                </p>
                <p>Would you like to create an exchange instead?</p>
              </>
            }
            title={`ARE YOU SURE YOU DON'T WANT TO EXCHANGE?`}
          ></ServiceOffer>
          <ServiceOffer
            displayPopup={displayFitPopup}
            hidePopup={hideFitPopup}
            content={
              <p>
                Are you sure you don’t want to exchange your product and enjoy
                FREE shipping and a FREE post returns label?
              </p>
            }
            title={`ARE YOU SURE YOU DON'T WANT TO EXCHANGE`}
          ></ServiceOffer> */}
        </div>
      </div>
    </>
  ) : (
    <Redirect to="/" />
  );
}

export default Return;
