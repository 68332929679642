import React, { useEffect, useState } from "react";
import "./Index.css";
import Login from "./login";
import { useSelector, useDispatch } from "react-redux";
// import {
//   selectOrderNumber,
//   setOrderNumber,
//   setStoreId,
//   setEmail,
//   clearCustomer,
//   selectEmail,
//   setCustomerId,
//   setOrderId,
//   setIsUs,
//   setIsExchangeable,
// } from "../../redux/slices/customer/customerSlice";
// import {
//   clearOrder,
//   getItems,
//   getReturnRecords,
//   selectOrder,
//   setComfortGurantee,
//   setDate,
// } from "../../redux/slices/order/orderSlice";
import { toast } from "react-toastify";
import Spinner from "../shared/Spinner";
// import { setStepStatus } from "../../redux/slices/step/stepSlice";
// import { setTitle } from "../../redux/slices/title/titleSlice";
import * as authApi from "../../api/authApi";
import {
  // generateToken,
  // selectToken,
  generateMerchant,
  // selectMerchant,
} from "../../redux/slices/auth/authSlice";
// import { getCSRF } from "../../api/authApi";

function MerchantLogin({ history, location }) {
  // const orderNumber = useSelector(selectOrderNumber);
  // const email = useSelector(selectEmail);
  // const token = useSelector(selectToken);
  // const merchant = useSelector(selectMerchant);
  const [emaillogin, setEmailLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errors] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const validateOrderNumber = () => {
    setLoading(true);
    authApi.getMerchantAuth(emaillogin, password).then((res) => {
      console.log("res", res);
      if (res.success) {
        toast.success(res.message);
        dispatch(generateMerchant(true));
        history.push("/");
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
  };

  const showForm = () => {
    return (
      <>
        <Login
          setEmail={setEmailLogin}
          setPassword={setPassword}
          password={password}
          email={emaillogin}
          onSave={() => validateOrderNumber()}
          errors={errors}
        />
      </>
    );
  };

  return loading ? (
    <>
      <div className="content customer">
        <div className="inner-content">
          <Spinner />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="content customer">
        <div className="inner-content">{showForm()}</div>
      </div>
    </>
  );
}

export default MerchantLogin;
