import React from "react";
import "./Index.css";
import { Header } from "rsuite";
import CancelOrder from "../buttons/CancelOrder";
import { Link, useHistory } from "react-router-dom";
import {
  // generateToken,
  // selectToken,
  generateMerchant,
  selectMerchant,
} from "../../../redux/slices/auth/authSlice";
import { useSelector, useDispatch } from "react-redux";
import { Icon, IconButton } from "rsuite";

function Index() {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(generateMerchant(false));
    history.push("/");
  };
  const merchant = useSelector(selectMerchant);
  return (
    <Header>
      <img
        src="https://strivefootwear.com/media/theme_options/default/Strive-Logo-Black_Orange_1.png"
        height="40"
      />
      <h1 class="app-title">Returns Platform </h1>
      <Link
        to={{
          pathname: "https://strivefootwear.com",
        }}
      />
      {merchant && (
        <IconButton
          onClick={handleClick}
          icon={<Icon icon="arrow-right" />}
          placement="right"
          style={{ float: "right", margin: "18px" }}
          color={"lightgrey"}
        >
          Log out
        </IconButton>
      )}
    </Header>
  );
}

export default Index;
