import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Loader } from "rsuite";
import { selectStock } from "../../../redux/slices/stock/stockSlice";
import { selectIsUs } from "../../../redux/slices/customer/customerSlice";
import SelectInput from "../../shared/SelectInput";

function Size({
  size,
  sizesOptions,
  setSizesOptions,
  exchangeProducts,
  getOptionLabel,
  itemId,
  onChange,
  sizesAvailable,
  setSizesAvailable,
  setNoOptionsAvailable,
  family,
}) {
  const [loading, setLoading] = useState(true);
  // const [isUs, setIsUs] = useState(true);
  const isUs = useSelector(selectIsUs);
  const stock = useSelector(selectStock);

  useEffect(() => {
    if (!sizesOptions || sizesOptions.length === 0) {
      setLoading(true);
      prepareSizes();
    }
  }, [size]);

  const prepareSizes = () => {
    let simpleProducts = exchangeProducts.filter((product) => {
      return product.type_id === "simple";
    });
    // let usProducts = [];
    console.log("simpleProducts", simpleProducts);
    if (isUs) {
      simpleProducts = simpleProducts.filter((pd) => {
        return pd.sku.includes("US");
      });
    }

    // console.log("usProducts", simpleProducts);
    console.log("simpleProducts exchangeProducts", simpleProducts);

    let sizesUsed = [];
    let productsInStock = filterAvailable(simpleProducts);
    console.log("productsInStock", productsInStock);

    console.log("isUs", isUs);
    productsInStock.map((product) => {
      let optionValue = product.custom_attributes.find((obj) => {
        if (isUs) {
          return obj.attribute_code === "us_size";
        } else {
          return obj.attribute_code === "size";
        }
      });

      console.log("optionValue size", optionValue);
      let sizeLabel = "";
      if (isUs) {
        console.log("this is US customer", optionValue);
        sizeLabel = getOptionLabel("us_size", optionValue);
      } else {
        sizeLabel = getOptionLabel("size", optionValue);
      }
      console.log("sizeLabel", sizeLabel);
      setLoading(false);

      let newSizesAvailable = sizesAvailable;
      newSizesAvailable.push(product);

      setSizesAvailable(newSizesAvailable);
      if (!sizesUsed.includes(sizeLabel)) {
        let newSizes = sizesOptions;
        newSizes.push({
          value: sizeLabel + " / " + product.sku,
          text: sizeLabel,
        });
        setSizesOptions(newSizes);
        sizesUsed.push(sizeLabel);
      }
    });

    if (!sizesOptions || sizesOptions.length === 0) {
      setLoading(false);
      setNoOptionsAvailable(true);
    }
    setLoading(false);
  };

  const filterAvailable = (products) => {
    let stocks = products.filter((product) => {
      let inStock = stock.filter((stockStatus) => {
        if (isUs) {
          return "US" + String(stockStatus.sku) === String(product.sku);
        } else {
          return String(stockStatus.sku) === String(product.sku);
        }
      });
      if (inStock && inStock.length > 0) {
        return inStock[0].freestock > 2;
      }
    });
    return stocks;
  };

  return !loading ? (
    <>
      <SelectInput
        name="size"
        onChange={(e) => onChange(e, itemId)}
        defaultOption="Please Select New Size"
        value={size[itemId]}
        label=""
        disabled={false}
        options={sizesOptions}
      />
    </>
  ) : (
    <td>
      <Loader content="Loading Sizes" />
    </td>
  );
}

export default Size;
