import React from "react";
import { useSelector } from "react-redux";

import {
  canOnlyReturnFaulty,
  hasComfortGurantee,
} from "../../../redux/slices/order/orderSlice";

import "./Index.css";

function Notice() {
  const onlyReturnFaulty = useSelector(canOnlyReturnFaulty);
  const comfortGuarantee = useSelector(hasComfortGurantee);
  console.log(comfortGuarantee);
  return onlyReturnFaulty ? (
    <p className="notice">
      Note: As this order is over our 30 day terms, you will only be able to
      return these goods as faulty
    </p>
  ) : comfortGuarantee ? (
    <p className="notice comfortGuarantee">
      Note: You claimed the 30 Day comfort guarantee with this order. This
      allows you to return these items back worn if you are not happy with them.
    </p>
  ) : null;
}

export default Notice;
