import React from "react";
import Routes from "./app/route/Routes.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/styles/rsuite-default.css";
import { ErrorBoundary } from "react-error-boundary";
import Header from "./app/shared/header/Index.js";
import Progress from "./app/shared/progress/Index";
import Notice from "./app/shared/notice/Index.js";
import { Container, Content } from "rsuite";
import PageTitle from "./app/shared/pagetitle/PageTitle";
import Footer from "./app/shared/footer/Footer.js";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container ">
        <Container>
          <Header />
          <PageTitle />
          <Content>
            <Notice />
            <Progress />
            <Routes />
          </Content>
          <Footer />
        </Container>
      </div>
      <ToastContainer autoClose={3000} hideProgressBar />
    </ErrorBoundary>
  );
}

export default App;
