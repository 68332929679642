import React from "react";
import { useSelector } from "react-redux";
import { Steps } from "rsuite";
import { selectStep } from "../../../redux/slices/step/stepSlice";
import "./Index.css";

export default function Progress() {
  const step = useSelector(selectStep);

  return step > 0 && step !== 3 ? (
    <div className="steps">
      <Steps current={step}>
        <Steps.Item title="Load Order" />
        <Steps.Item title="Configure" />
        <Steps.Item title="Review" />
        <Steps.Item title="Complete" />
      </Steps>
    </div>
  ) : null;
}
