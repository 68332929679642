import React, { useEffect, useState } from "react";
import "./Index.css";
import SelectInput from "../shared/SelectInput";
import { useSelector } from "react-redux";
import { selectExtendedReasons } from "../../redux/slices/reason/reasonSlice";
import { selectToken } from "../../redux/slices/auth/authSlice";
import TextArea from "../shared/TextArea";
import { Uploader } from "rsuite";
import { getCSRF } from "../../api/authApi";

function ExtendedReasons({
  itemId,
  reasonId,
  extendedReason,
  onChange,
  selected,
  defaultFileList,
  setDefaultFileList,
}) {
  const reasons = useSelector(selectExtendedReasons);
  const token = useSelector(selectToken);

  const [csrf, setCsrf] = useState();

  useEffect(() => {
    getCSRF().then((res) => {
      setCsrf(res.CSRFToken);
    });
  }, []);

  const generateLabels = () => {
    let labels = [];
    for (let reason in reasons) {
      let option = { value: reasons[reason].id, text: reasons[reason].label };

      labels.push(option);
    }
    return labels;
  };

  const setFile = (file) => {
    let newFileList = defaultFileList;
    if (newFileList[itemId] && newFileList[itemId].length > 0) {
      newFileList[itemId].push({
        name: file.name,
        filename: file.filename,
        imageKey: file.imageKey,
      });
    } else {
      newFileList[itemId] = [
        {
          name: file.name,
          filename: file.filename,
          imageKey: file.imageKey,
        },
      ];
    }

    setDefaultFileList(newFileList);
  };

  if (selected[itemId]) {
    switch (reasonId) {
      case "2":
        return (
          <>
            <SelectInput
              name="extendedReason"
              onChange={(e) => onChange(e, itemId)}
              defaultOption="Why Does It Not Fit?"
              value={extendedReason[itemId]}
              label=""
              disabled={false}
              options={generateLabels()}
            />
          </>
        );
      case "1":
        return (
          <>
            <TextArea
              name="extendedReason"
              label=""
              onChange={(e) => onChange(e, itemId)}
              placeholder="Enter more details of the fault"
              value={extendedReason[itemId]}
            />
            <Uploader
              action={process.env.REACT_APP_IMAGE_URL}
              defaultFileList={
                defaultFileList && defaultFileList.length > 0
                  ? defaultFileList[itemId]
                  : defaultFileList
              }
              name="faultyImages"
              headers={{
                "CSRF-Token": csrf,
                authorization: "Bearer " + token,
              }}
              draggable
              onSuccess={setFile}
              renderFileInfo={(file) => {
                return (
                  <div>
                    <span>File Name: {file.name}</span>
                  </div>
                );
              }}
            >
              <div>Click or Drag files to this area to upload</div>
            </Uploader>
          </>
        );
      default:
        return null;
    }
  } else {
    return null;
  }
}

export default ExtendedReasons;
