import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectOrderNumber,
  selectCustomerId,
  selectEmail,
  selectOrderId,
  selectIsUs,
} from "../../redux/slices/customer/customerSlice";
import ItemList from "./ItemList";
import { saveReturn } from "../../api/orderApi";
import {
  selectReturnItems,
  setRmaNumber,
} from "../../redux/slices/return/returnSlice";
import { setSubTitle } from "../../redux/slices/title/titleSlice";
import { setStepStatus } from "../../redux/slices/step/stepSlice";
import { toast } from "react-toastify";
import Back from "../shared/buttons/Back";
import { ButtonToolbar } from "rsuite";
import ConfirmOrder from "../shared/buttons/ConfirmOrder";
import { selectToken } from "../../redux/slices/auth/authSlice";
import { getCSRF } from "../../api/authApi";

function Confirm({ history }) {
  const orderNumber = useSelector(selectOrderNumber);
  const customerId = useSelector(selectCustomerId);
  const customerEmail = useSelector(selectEmail);
  const returnItems = useSelector(selectReturnItems);
  const orderId = useSelector(selectOrderId);
  const isUs = useSelector(selectIsUs);
  const token = useSelector(selectToken);

  const [csrf, setCsrf] = useState();
  const [newValues, setNewValues] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSubTitle("Confirm Your Return"));
  }, [returnItems]);

  useEffect(() => {
    getCSRF().then((res) => {
      setCsrf(res.CSRFToken);
    });
  }, []);

  const handleConfirm = () => {
    console.log("returnItems confirm", returnItems);
    saveReturn(
      orderNumber,
      customerId,
      orderId,
      customerEmail,
      returnItems,
      newValues,
      isUs,
      token,
      csrf
    )
      .then((result) => {
        if (result) {
          dispatch(setRmaNumber(result));
          dispatch(setStepStatus(3));
          history.replace("/success");
        }
      })
      .catch((e) => {
        toast.error(
          "Sorry your request could not be submitted at this time, please try again later."
        );
      });
  };

  const handleClick = () => {
    dispatch(setStepStatus(1));

    history.push("/return");
  };

  return (
    <div className="content customer">
      <div className="inner-content">
        <h3>CONFIRM YOUR RETURN OF #{orderNumber}</h3>
        <table className="item-return-table confirm-table">
          <ItemList setNewValues={setNewValues} newValues={newValues} />
        </table>
        <ButtonToolbar>
          <Back handleClick={handleClick} content={"Change Selections"} />
          <ConfirmOrder
            handleClick={handleConfirm}
            content={"Submit Return Request"}
          />
        </ButtonToolbar>
      </div>
    </div>
  );
}

export default Confirm;
