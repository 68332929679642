import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectStock } from "../../../redux/slices/stock/stockSlice";
import SelectInput from "../../shared/SelectInput";
import TextInputSimple from "../../shared/TextInputSimple";
import { selectIsUs } from "../../../redux/slices/customer/customerSlice";
import "./ProductFinder.css";
import {
  selectUsSizeOptions,
  selectColourOptions,
} from "../../../redux/slices/option/optionSlice";
import { getProducts } from "../../../api/productApi";
import { selectToken } from "../../../redux/slices/auth/authSlice";

function ProductFinder({
  onChange,
  product,
  searchEntry,
  itemId,
  noOptionsAvailable,
}) {
  const [errors] = useState([]);
  const [result, setResult] = useState([]);
  const [options, setOptions] = useState([]);
  const [productsInList, setProductsInList] = useState([]);
  const isUs = useSelector(selectIsUs);
  const stock = useSelector(selectStock);
  const usSizeOptions = useSelector(selectUsSizeOptions);
  const usColorOptions = useSelector(selectColourOptions);

  const token = useSelector(selectToken);

  useEffect(() => {
    findProduct();
  }, [searchEntry]);

  useEffect(() => {
    console.log("useEffect options", options);
  }, [options]);

  const findProduct = async () => {
    if (searchEntry && searchEntry[itemId] && searchEntry[itemId].length > 3) {
      let items = [];

      items = stock.filter((stockItem) => {
        return String(stockItem.description)
          .toLowerCase()
          .includes(String(searchEntry[itemId]).toLowerCase());
      });
      let data = [];
      if (isUs) {
        let skus = [];
        items.map((row) => {
          if (row.freestock > 2) {
            let tempSku = "US" + row.sku;
            skus.push(tempSku);
            return row;
          }
        });
        data = await getProducts(skus.join(","), "strive_us", token);
        console.log("data", data);
        console.log("us sizeOptions", usSizeOptions);

        console.log("searched skus", skus);
      }

      console.log("items findProduct", items);
      console.log("searchEntry[itemId]", searchEntry[itemId]);
      console.log("usColorOptions", usColorOptions);

      if (items.length > 1) {
        setResult(items[0].description.split("PAIR")[0]);
        items.map((item) => {
          if (item.freestock > 2) {
            let newOptions = options;
            let newProductsInList = productsInList;
            let sku = item.sku;
            if (isUs) {
              sku = "US" + item.sku;
            }

            if (!newProductsInList.includes(sku)) {
              console.log("here inside");
              let sizeLabel = item.description;
              let productName = "";

              if (isUs) {
                if (item.description.toLowerCase().includes(" uk")) {
                  productName = item.description.split("UK")[0];
                } else if (item.description.toLowerCase().includes(" size")) {
                  productName = item.description.split("size")[0];
                }
                let productData = data.find((row) => row.sku == sku);
                let option = productData.custom_attributes.find((obj) => {
                  return obj.attribute_code === "us_size";
                });

                if (usSizeOptions) {
                  let result = usSizeOptions.find((obj) => {
                    return String(obj.value) === String(option.value);
                  });
                  if (result && result.label) {
                    sizeLabel = productName + " US " + result.label;
                  }
                }
              }
              newOptions.push({
                value: item.description + " / " + sku,
                text: sizeLabel,
              });
              newProductsInList.push(sku);
              setProductsInList(newProductsInList);
              setOptions(newOptions);
            }
          }
        });
      }
    } else {
      setOptions([]);
      setResult([]);
      setProductsInList([]);
    }
  };

  const getSelectInput = () => {
    console.log("getSelectInput", options);
    return options.length > 0 ? (
      <SelectInput
        name="product"
        onChange={(e) => {
          onChange(e, itemId);
        }}
        defaultOption="Please Select New Product"
        value={product[itemId]}
        label=""
        disabled={false}
        options={options}
      />
    ) : null;
  };

  return (
    <>
      <td>
        {noOptionsAvailable ? (
          <p className="no-options-notice">
            No options for this style are available, do you want to exchange for
            a different product?
          </p>
        ) : (
          ""
        )}
        <div className="diffent-product">
          <TextInputSimple
            name="product-search"
            label="Search for product by name and colour"
            value={searchEntry[itemId]}
            onChange={(e) => onChange(e, itemId)}
            error={errors.newProduct}
          />
          {getSelectInput()}
        </div>
      </td>
    </>
  );
}

export default ProductFinder;
