import { combineReducers, configureStore } from "@reduxjs/toolkit";
import customerReducer from "./slices/customer/customerSlice";
import returnReducer from "./slices/return/returnSlice";
import orderReducer from "./slices/order/orderSlice";
import optionReducer from "./slices/option/optionSlice";
import stockReducer from "./slices/stock/stockSlice";
import reasonReducer from "./slices/reason/reasonSlice";
import titleReducer from "./slices/title/titleSlice";
import stepReducer from "./slices/step/stepSlice";
import authReducer from "./slices/auth/authSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import thunk from "redux-thunk";

const reducers = combineReducers({
  customer: customerReducer,
  return: returnReducer,
  order: orderReducer,
  reason: reasonReducer,
  option: optionReducer,
  stock: stockReducer,
  step: stepReducer,
  title: titleReducer,
  auth: authReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
