import { handleResponse, handleError } from "./apiUtils";

const api_url = process.env.REACT_APP_API_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      if (response.items) {
        return response.items;
      }
      return response;
    } else {
      return null;
    }
  });
}

export function loadFamilyOptions(passKey) {
  return fetch(api_url + "/options/family", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function loadSizeOptions(passKey) {
  return fetch(api_url + "/options/size", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
export function loadUsSizeOptions(passKey) {
  return fetch(api_url + "/options/us_size", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function loadColourOptions(passKey) {
  return fetch(api_url + "/options/colour", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
