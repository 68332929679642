import React, { useEffect, useState } from "react";
import { Loader } from "rsuite";
import SelectInput from "../../shared/SelectInput";

function Colour({
  colour,
  getOptionLabel,
  size,
  itemId,
  onChange,
  refundType,
  selected,
  exchangeProducts,
  sizesAvailable,
  setNoOptionsAvailable,
  family,
}) {
  const [colours, setColours] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (size && size[itemId]) {
      setColours([]);
    }
  }, [size]);

  useEffect(() => {
    if (colours && colours.length === 0 && size && size[itemId]) {
      setLoading(true);
      prepareColours();
    }
  }, [colours]);

  const prepareColours = () => {
    if (family.toLowerCase().includes("insole")) {
      return false;
    }
    console.log("exchangeProducts", exchangeProducts);
    let configProducts = exchangeProducts.filter((product) => {
      return product.type_id === "configurable";
    });
    console.log("configProducts", configProducts);

    configProducts.map((product) => {
      let optionValue = product.custom_attributes.find((obj) => {
        return obj.attribute_code === "color";
      });
      console.log("optionValue", optionValue);
      let colorLabel = getOptionLabel("color", optionValue);
      console.log("colorLabel", colorLabel);

      let newColours = colours;
      newColours.push({
        value: colorLabel + " / " + product.sku,
        text: colorLabel,
      });
      setColours(newColours);
    });
    filterColoursAvailable();
  };

  const filterColoursAvailable = () => {
    if (size[itemId] === "Please Select New Size") {
      return false;
    }
    console.log("colors", colours);
    let coloursUsed = [];
    let coloursAvailable = colours.filter((colour) => {
      if (!coloursUsed.includes(colour)) {
        let colourSku = colour.value.split("/")[1];
        let available = sizesAvailable.filter((sizeAvailable) => {
          let productFromSku = sizeAvailable.sku.split("-")[0];
          let sizeFromSku = sizeAvailable.sku.split("-")[1];
          return (
            productFromSku
              .trim()
              .toLowerCase()
              .includes(colourSku.trim().toLowerCase()) &&
            parseInt(size[itemId].split("/")[0].trim()) ===
              parseInt(sizeFromSku)
          );
        });
        if (available && available.length > 0) {
          coloursUsed.push(colour);
          return true;
        }
      }
    });
    if (!coloursAvailable || coloursAvailable.length === 0) {
      setLoading(false);
      setNoOptionsAvailable(true);
    } else {
      setColours(coloursAvailable);
      setLoading(false);
    }
  };

  const content = () => {
    return refundType[itemId] === "Exchange" &&
      size[itemId] != null &&
      size[itemId] !== "Please Select New Size" &&
      selected[itemId] &&
      !loading ? (
      <>
        <SelectInput
          name="colour"
          onChange={(e) => onChange(e, itemId)}
          defaultOption="Please Select New Colour"
          value={colour[itemId]}
          label=""
          disabled={false}
          options={colours}
        />
      </>
    ) : size[itemId] != null && size[itemId] !== "Please Select New Size" ? (
      <td>
        <Loader content="Loading Colours" />
      </td>
    ) : null;
  };

  return family && !family.toLowerCase().includes("insole") ? content() : null;
}

export default Colour;
