import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  orderNumber: "",
  email: "",
  customerId: "",
  orderId: "",
  storeId: 1,
  isUs: false,
  isExchangeable: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState: initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    setOrderNumber: (state, action) => {
      state.orderNumber = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },
    setIsUs: (state, action) => {
      state.isUs = action.payload;
    },
    setIsExchangeable: (state, action) => {
      state.isExchangeable = action.payload;
    },
    clearCustomer: (state) => initialState,
  },
});

export const {
  setEmail,
  setOrderNumber,
  setCustomerId,
  setOrderId,
  clearCustomer,
  setStoreId,
  setIsUs,
  setIsExchangeable,
} = customerSlice.actions;

export const selectEmail = (state) =>
  state.customer && state.customer.email ? state.customer.email : null;

export const selectOrderNumber = (state) =>
  state.customer && state.customer.orderNumber
    ? state.customer.orderNumber
    : null;

export const selectCustomerId = (state) =>
  state.customer && state.customer.customerId
    ? state.customer.customerId
    : null;

export const selectOrderId = (state) =>
  state.customer && state.customer.orderId ? state.customer.orderId : null;

export const selectStoreId = (state) =>
  state.customer && state.customer.storeId ? state.customer.storeId : null;

export const selectIsUs = (state) =>
  state.customer && state.customer.isUs ? state.customer.isUs : null;

export const selectIsExchangeable = (state) =>
  state.customer && state.customer.isExchangeable
    ? state.customer.isExchangeable
    : null;

export default customerSlice.reducer;
