import { createSlice } from "@reduxjs/toolkit";
import { getStock, getUsStock } from "../../../api/stockApi";

export const initialState = {
  value: {},
};

export const stockSlice = createSlice({
  name: "stock",
  initialState: initialState,
  reducers: {
    setStock: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setStock, setUsStock } = stockSlice.actions;

export const getStockAvailability = (token) => (dispatch) => {
  return getStock(token).then((stock) => {
    return dispatch(setStock(stock));
  });
};

export const getUsStockAvailability = (token) => (dispatch) => {
  return getUsStock(token).then((stock) => {
    return dispatch(setStock(stock));
  });
};

export const selectStock = (state) => {
  return state.stock && state.stock.value && state.stock.value.length > 0
    ? state.stock.value
    : null;
};

export default stockSlice.reducer;
