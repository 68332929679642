import { handleResponse, handleError } from "./apiUtils";
const api_url = process.env.REACT_APP_API_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      if (response.items) {
        return response.items;
      }
      return response;
    } else {
      return null;
    }
  });
}

export function getOrder(orderId, passKey, token) {
  return fetch(api_url + "/orders/order", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
      "CSRF-Token": token,
    },
    body: JSON.stringify({
      orderId: orderId,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function getReturnRecord(orderId, passKey, token) {
  return fetch(api_url + "/orders/return", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
      "CSRF-Token": token,
    },
    body: JSON.stringify({
      orderId: orderId,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function saveReturn(
  orderNumber,
  customerId,
  orderId,
  customerEmail,
  items,
  newValues,
  isUs,
  passKey,
  token
) {
  return fetch(api_url + "/orders/save", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
      "CSRF-Token": token,
    },
    body: JSON.stringify({
      orderNumber,
      customerId,
      customerEmail,
      orderId,
      items,
      newValues,
    }),
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
