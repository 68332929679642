import React, { useEffect, useState } from "react";
import Next from "../shared/buttons/Next";
import "./Popup.css";

function ConfirmBtn({
  selected,
  refundType,
  extendedReasons,
  reason,
  size,
  colour,
  exchangeType,
  product,
  onSave,
  order,
}) {
  const [canConfirm, setCanConfirm] = useState(false);

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    validateForm();
  }, [
    selected,
    refundType,
    extendedReasons,
    reason,
    size,
    colour,
    exchangeType,
    product,
  ]);

  const validateForm = () => {
    if (!itemsAreSelected()) {
      setCanConfirm(false);
      return false;
    }
    if (order && order.items) {
      order.items.map((item) => {
        let itemId = item.item_id;
        if (isSelected(itemId)) {
          let itemName = item.hasOwnProperty("parent_item")
            ? item.parent_item.name
            : item.name;
          if (
            validateRefundType(itemId) &&
            validateRefundReason(itemId) &&
            validateReason(itemId) &&
            validateExchange(itemId, itemName)
          ) {
            setCanConfirm(true);
          } else {
            setCanConfirm(false);
            return false;
          }
        }
      });
    }
  };

  const itemsAreSelected = () => {
    let itemSelected = false;
    if (selected && Object.keys(selected).length > 0) {
      Object.keys(selected).map((item) => {
        if (selected[item]) {
          itemSelected = true;
        }
      });
    }

    return itemSelected;
  };

  const isSelected = (itemId) => {
    return selected[itemId];
  };

  const validateRefundType = (itemId) => {
    return refundType[itemId] && refundType[itemId] !== "Please Select";
  };

  const validateRefundReason = (itemId) => {
    return reason[itemId] && reason[itemId] !== "Please Select";
  };

  const validateReason = (itemId) => {
    if (String(reason[itemId]) === "2") {
      if (
        !extendedReasons[itemId] &&
        extendedReasons[itemId] !== "Please Select"
      ) {
        return false;
      }
    }
    return true;
  };

  const validateExchange = (itemId, itemName) => {
    if (refundType[itemId] === "Exchange") {
      let hasSize =
        size[itemId] !== undefined && size[itemId] !== "Please Select New Size";

      let hasColour =
        colour[itemId] !== undefined &&
        colour[itemId] !== "Please Select New Colour";

      let isNotInsole = !itemName.toLowerCase().includes("insole");

      if (hasSize) {
        if (isNotInsole) {
          if (hasColour) {
            return true;
          }
          return false;
        }
        return true;
      } else if (product[itemId] !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return canConfirm ? (
    <Next handleClick={onSave} content={"Next"} color={"green"} />
  ) : (
    <Next
      handleClick={onSave}
      content={"Select options to continue"}
      disabled={true}
    />
  );
}

export default ConfirmBtn;
