import React, { useEffect, useState } from "react";
import "./Index.css";
import EmailForm from "./Email";
import OrderNumberForm from "./OrderNumber";
import { useSelector, useDispatch } from "react-redux";
import {
  selectOrderNumber,
  setOrderNumber,
  setStoreId,
  setEmail,
  clearCustomer,
  selectEmail,
  setCustomerId,
  setOrderId,
  setIsUs,
  setIsExchangeable,
} from "../../redux/slices/customer/customerSlice";
import {
  clearOrder,
  getItems,
  getReturnRecords,
  selectOrder,
  setComfortGurantee,
  setDate,
} from "../../redux/slices/order/orderSlice";
import { toast } from "react-toastify";
import Spinner from "../shared/Spinner";
import { setStepStatus } from "../../redux/slices/step/stepSlice";
import { setTitle } from "../../redux/slices/title/titleSlice";
import {
  generateToken,
  selectToken,
  selectMerchant,
} from "../../redux/slices/auth/authSlice";
import { getCSRF } from "../../api/authApi";
import CantReturnPopup from "./Popup";

function Customer({ history, location }) {
  const orderNumber = useSelector(selectOrderNumber);
  const order = useSelector(selectOrder);
  const email = useSelector(selectEmail);
  const token = useSelector(selectToken);
  const merchant = useSelector(selectMerchant);
  const [errors] = useState({});
  const [emailEntry, setEmailEntry] = useState(email);
  const [orderNumberEntry, setOrderNumberEntry] = useState(orderNumber);
  const [loading, setLoading] = useState(false);
  const [canOnlyReportFaulty] = useState(false);
  const [csrf, setCsrf] = useState();
  const [showPopup, setShowPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getCSRF().then((res) => {
      setCsrf(res.CSRFToken);
    });

    if (!token || token === "") {
      dispatch(generateToken()).then((result) => {
        if (result && result.success) {
          toast.error("authenticated");
        } else {
          toast.error("Cannot authenticate");
        }
      });
    }

    if (order && emailEntry) {
      history.push("/return");
    } else {
      let params = location.search;
      params = params.split("&");
      for (let param in params) {
        params[param] = params[param].split("=");
      }
      let email = "";
      let orderNumber = "";
      for (let param in params) {
        if (params[param][0].includes("email")) {
          setLoading(true);
          email = params[param][1];
          setEmailEntry(email);
          dispatch(setEmail(email));
        }
        if (params[param][0].includes("orderNo")) {
          orderNumber = params[param][1];
          setOrderNumberEntry(orderNumber);
          dispatch(setOrderNumber(orderNumber));
          // eslint-disable-next-line no-loop-func
          dispatch(getItems(orderNumber, token, csrf)).then((order) => {
            if (order && order.payload.length > 0) {
              let isUs = false;
              if (order.payload[0].store_id == 5) {
                isUs = true;
              }
              dispatch(setIsUs(isUs));
              dispatch(setStoreId(order.payload[0].store_id));
              let validDate = validateOrderDate(order);
              console.log("here i am in useeffect");
              if (validDate) {
                validateEmailAddress(order.payload[0], email);
              } else {
                toast.error("Sorry, refund is not possible on this order");
              }
            } else {
              toast.error("Invalid Order Number");
            }
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (orderNumber) {
      dispatch(setTitle("Creating return for order #" + orderNumber));
    } else {
      dispatch(setTitle("Create a Return"));
    }
  }, [orderNumber]);

  const validateEmailAddress = (order, email) => {
    let customerId = '';
    if(order.customer_is_guest === 1){

      customerId= 'Guest'; //Guest customer
    }
    if(order.hasOwnProperty('customer_id')){
      customerId = order.customer_id;
    }
    console.log('customerId',customerId)
    if (
      order &&
      Object.keys(order).length > 0 &&
      order.customer_email &&
      customerId
    ) {
      if (order.customer_email === email) {
        dispatch(setEmail(email));
        toast.success("Order Loaded");
        validateComfortGurantee(order);
        if (canOnlyReportFaulty) {
          dispatch(setDate(true));
        }
        dispatch(setStepStatus(1));
        dispatch(setCustomerId(customerId));
        dispatch(setOrderId(order.items[0].order_id));
        dispatch(getReturnRecords(orderNumber, token, csrf));
        setTimeout(() => {
          history.push("/return");
        }, 2000);
        // history.push("/return");
      } else {
        setEmailEntry("");
        setOrderNumberEntry("");
        toast.error("No Order Found");
        dispatch(clearOrder());
        dispatch(clearCustomer());
        setLoading(false);
      }
    }
  };
  const hidePopup = (e) => {
    setShowPopup(false);
  };

  const getStatus = (status) => {
    switch (status) {
      case "new":
        return "We are expecting your return of this item";
      case "rejected":
        return "This item was rejected and will be sent back to you";
      case "complete":
        return "Your return has been successfully processed";
      case "pending":
        return "We have received your goods back.";
      case "issue":
        return "We have received your goods back.";
      default:
        return "";
    }
  };
  const validateOrderDate = (order) => {
    console.log("order", order);
    if (order[0] && order[0].created_at) {
      console.log("here if");
      let date = order[0].created_at.replace(/-/g, "/");
      let splitDate = order[0].created_at.split(" ")[0].split("-");
      console.log("split", splitDate);
      console.log("date", date);

      let createdAt = new Date(date);
      console.log("createdAt", createdAt);

      let sixMonthsLater = new Date(date);
      // let sixMonthsLater = new Date(
      //   parseInt(splitDate[0]) +
      //     "-" +
      //     (parseInt(splitDate[1], 10) + 6) +
      //     "-" +
      //     parseInt(splitDate[2])
      // );
      sixMonthsLater.setMonth(sixMonthsLater.getMonth() + 6);
      console.log("sixMonthsLater", sixMonthsLater);

      let thirtyDaysFromOrder = new Date(date);
      thirtyDaysFromOrder.setMonth(thirtyDaysFromOrder.getMonth() + 1);

      console.log("thirtyDaysFromOrder", thirtyDaysFromOrder);

      let today = new Date();
      let disableExchangeDate = new Date("2023/06/12");
      console.log("today", today);
      console.log("disableExchangeDate", disableExchangeDate);

      //exchanges are disabled for orders placed from 12-06-2023 monday
      if (createdAt > disableExchangeDate) {
        console.log("order date is less than disable date");
        dispatch(setIsExchangeable(false));
      }
      // if (createdAt < sixMonthsLater) {
      //   console.log("here createdAt < sixMonthsLater");
      //   return true;
      // } else
      if (today > thirtyDaysFromOrder) {
        console.log("here today > thirtyDaysFromOrder");

        return false;
      }
    }
    console.log("here outside");
    return true;
  };

  const validateComfortGurantee = (order) => {
    if (order && order.discount_description) {
      let discountDescription = order.discount_description;

      if (discountDescription === "30 Day Comfort Guarantee") {
        dispatch(setComfortGurantee(true));
      }
    }
  };

  const validateOrderNumber = () => {
    setLoading(true);
    dispatch(getItems(orderNumberEntry, token, csrf)).then((order) => {
      if (order && order.payload.length > 0) {
        dispatch(setOrderNumber(orderNumberEntry));
        let isUs = false;
        if (order.payload[0].store_id == 5) {
          isUs = true;
        }
        dispatch(setIsUs(isUs));
        dispatch(setStoreId(order.payload[0].store_id));

        if (!merchant) {
          console.log("no merchant 1");
          let validDate = validateOrderDate(order.payload);
          console.log("here i am in validateOrderNumber");
          if (!validDate) {
            setShowPopup(true);
            toast.error("Sorry, refund is not possible on this order");
            dispatch(clearOrder());
            dispatch(clearCustomer());
          }
        }

        setLoading(false);
      } else {
        toast.error("No Order Found");
        setLoading(false);
      }
    });
  };

  const showForm = () => {
    return order !== null && Object.keys(order).length > 0 ? (
      <>
        <h3>Enter the email address on the order</h3>
        <EmailForm
          onChange={(value) => setEmailEntry(value)}
          orderNumber={orderNumber}
          email={emailEntry}
          errors={errors}
          onSave={() => validateEmailAddress(order, emailEntry)}
        />
      </>
    ) : (
      <>
        <OrderNumberForm
          onChange={(value) => {
            setOrderNumberEntry(value);
          }}
          orderNumber={orderNumberEntry}
          onSave={() => validateOrderNumber()}
          errors={errors}
        />
      </>
    );
  };

  return loading ? (
    <>
      <div className="content customer">
        <div className="inner-content">
          <Spinner />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="content customer">
        <div className="inner-content">
          {showForm()}
          <CantReturnPopup
            displayPopup={showPopup}
            hidePopup={hidePopup}
            content={
              <p>
                Your purchase date exceeds our 30 day return policy. You are no
                longer able to return your order. If you have any queries
                regarding your order, please contact our Customer Care team at:
                sales@strivefootwear.com
              </p>
            }
            title={`Cant Return`}
          ></CantReturnPopup>
        </div>
      </div>
    </>
  );
}

export default Customer;
