import React, { useEffect, useState } from "react";
import Options from "./Options";
import ProductFinder from "./ProductFinder";

function Exchange({
  size,
  colour,
  itemId,
  onChange,
  refundType,
  selected,
  exchangeType,
  exchangeProducts,
  product,
  searchEntry,
  family,
}) {
  const [noOptionsAvailable, setNoOptionsAvailable] = useState(false);

  useEffect(() => {}, [noOptionsAvailable]);

  if (
    exchangeType &&
    Object.keys(exchangeType).length > 0 &&
    exchangeType[itemId]
  ) {
    return parseInt(exchangeType[itemId]) === 1 && !noOptionsAvailable ? (
      <Options
        size={size}
        colour={colour}
        itemId={itemId}
        onChange={onChange}
        refundType={refundType}
        selected={selected}
        exchangeProducts={exchangeProducts}
        setNoOptionsAvailable={setNoOptionsAvailable}
        family={family}
      />
    ) : (
      <>
        <ProductFinder
          onChange={onChange}
          itemId={itemId}
          product={product}
          searchEntry={searchEntry}
          noOptionsAvailable={noOptionsAvailable}
          family={family}
        ></ProductFinder>
      </>
    );
  } else {
    return null;
  }
}

export default Exchange;
