import React, { useEffect, useState } from "react";
import "./Index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCustomer,
  selectOrderNumber,
} from "../../redux/slices/customer/customerSlice";
import {
  clearReturn,
  selectRmaNumber,
} from "../../redux/slices/return/returnSlice";
import ItemList from "../confirm/ItemList";
import { setTitle } from "../../redux/slices/title/titleSlice";
import CancelOrder from "../shared/buttons/CancelOrder";
import StandardButton from "../shared/buttons/Standard";
import { clearOrder } from "../../redux/slices/order/orderSlice";
import { clearSteps } from "../../redux/slices/step/stepSlice";
import { ButtonToolbar } from "rsuite";
import { selectReturnItems } from "../../redux/slices/return/returnSlice";

function Success({ history }) {
  const orderNumber = useSelector(selectOrderNumber);
  const rmaNumber = useSelector(selectRmaNumber);
  const returnItems = useSelector(selectReturnItems);
  const [isFaulty, setIsFaulty] = useState(false);
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (returnItems) {
      returnItems.map((item, i) => {
        if (item.reason == 1) {
          setIsFaulty(true);
        }
      });
    }
  }, [returnItems]);
  // useEffect(() => {
  //   fetch("https://ipapi.co/json/", { mode: "cors" })
  //     .then((resp) => resp.json())
  //     .then((data) => {
  //       console.log(data);
  //       setCountry(data.country);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    fetch("https://ipinfo.io/json?token=98430a100d8873", { mode: "cors" })
      .then((resp) => {
        if (!resp.ok) {
          throw new Error("Network response was not ok");
        }
        return resp.json();
      })
      .then((data) => {
        console.log(data);
        setCountry(data.country);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        // Handle the error, e.g., set an error state or display a message to the user.
      });
  }, []);

  useEffect(() => {
    dispatch(setTitle("Return Created For Order #" + orderNumber));
  }, [rmaNumber]);

  return (
    <div className="content success">
      <div className="inner-container">
        {!isFaulty ? (
          <>
            <h3>Return Request Submitted</h3>
            <h4>This is what you are sending back to us</h4>

            <table className="item-return-table">
              <ItemList />
            </table>

            <div class="rma-box">
              <p>{rmaNumber}</p>
            </div>
          </>
        ) : (
          <>
            <h3 style={{ textTransform: "uppercase", textAlign: "center" }}>
              Return Request Submitted
            </h3>
            <p style={{ fontSize: "18px" }}>
              <b>
                As this item is faulty, your return request will need to be
                granted before you send it back.
              </b>
            </p>
            <p style={{ fontSize: "18px" }}>
              <b>You Will be notified via email when the return is granted.</b>
            </p>
          </>
        )}

        <section className="return-methods">
          <h4>What do I do next?</h4>
          <article className="return-method">
            <p class="sub-header">Have you got your original delivery note?</p>
            <p>
              Add the RMA number to the indicated space and ensure it is in the
              parcel when you send it back.
            </p>
          </article>
          <article className="return-method">
            <p class="sub-header">Don't have the delivery note?</p>
            <p>
              Print this page and ensure it is in the parcel when you send it
              back.
            </p>
          </article>
          <article className="return-method">
            <p class="sub-header">Don't have a printer or delivery note?</p>
            <p>
              Write the RMA number above on a covering letter and ensure it is
              in the parcel when you send it back.
            </p>
          </article>
          <article className="return-method">
            <p class="sub-header">How do I send my order back?</p>
            <p>
              As per our return policy it is your responsibility to ship the
              goods to us.
            </p>
          </article>
          <article className="return-method">
            <p class="sub-header">Address</p>
            {loading ? null : country == "US" ? (
              <p>19113 63rd Avenue Ne, Suite 4, Arlington, WA, 98223</p>
            ) : (
              <p>Strive Footwear, Emerald Way, Stone, Staffs, ST15 0SR</p>
            )}
          </article>
          {!isFaulty ? (
            <article className="return-method">
              <p class="warning">
                Ensure we receive an RMA number shown above, when we receive the
                parcel, without it you may experience a delay in processing it.
              </p>
            </article>
          ) : null}
        </section>
        <ButtonToolbar>
          <CancelOrder
            history={history}
            direction={"/"}
            colour={"green"}
            content={"Start New Return"}
          />
        </ButtonToolbar>
      </div>
    </div>
  );
}

export default Success;
