import React, { useEffect, useState } from "react";
import SelectInput from "../../shared/SelectInput";
import { Loader } from "rsuite";
import Exchange from "./Exchange";
import { useDispatch, useSelector } from "react-redux";
import { selectFamilyOptions } from "../../../redux/slices/option/optionSlice";
import { selectIsUs } from "../../../redux/slices/customer/customerSlice";
import {
  getStockAvailability,
  getUsStockAvailability,
} from "../../../redux/slices/stock/stockSlice";
import { getProductsFromFamily } from "../../../api/productApi";
import { selectToken } from "../../../redux/slices/auth/authSlice";

function Index({
  size,
  colour,
  itemId,
  onChange,
  refundType,
  selected,
  family,
  product,
  exchangeType,
  searchEntry,
}) {
  const familyOptions = useSelector(selectFamilyOptions);
  const isUs = useSelector(selectIsUs);
  const token = useSelector(selectToken);
  const [exchangeProducts, setExchangeProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("isUs", isUs);
    let exchange = refundType[itemId] === "Exchange" && selected[itemId];
    if (exchange) {
      setLoading(true);
      let options;

      if (family.toLowerCase().includes("insole")) {
        options = familyOptions.filter((option) => {
          return (
            String(option.label).toLowerCase() ===
            String("Insole").toLowerCase()
          );
        });
      } else {
        options = familyOptions.filter((option) => {
          return (
            String(option.label).toLowerCase() === String(family).toLowerCase()
          );
        });
      }

      if (options && options.length > 0) {
        return getProductsFromFamily(options[0].value, token, isUs).then(
          (products) => {
            console.log(products);
            if (products && products.length > 0) {
              setExchangeProducts(products);
              setLoading(false);
            } else {
              setLoading(false);
            }
          }
        );
      } else {
        setLoading(false);
      }
    } else {
      setExchangeProducts([]);
    }
    if (isUs) {
      dispatch(getUsStockAvailability(token));
    } else {
      dispatch(getStockAvailability(token));
    }
  }, [refundType[itemId]]);

  if (
    selected[itemId] &&
    refundType[itemId] &&
    refundType[itemId] === "Exchange"
  ) {
    return !loading ? (
      <>
        <td>
          <span>What do you want to exchange for?</span>
          <SelectInput
            name="exchange-type"
            onChange={(e) => onChange(e, itemId)}
            defaultOption="Please Select"
            value={exchangeType[itemId]}
            label=""
            disabled={false}
            options={[
              {
                value: 1,
                text: "Different Colour / Size",
              },
              {
                value: 2,
                text: "Different Product",
              },
            ]}
          />
        </td>
        <Exchange
          size={size}
          colour={colour}
          itemId={itemId}
          onChange={onChange}
          refundType={refundType}
          selected={selected}
          exchangeType={exchangeType}
          setLoading={setLoading}
          family={family}
          exchangeProducts={exchangeProducts}
          product={product}
          searchEntry={searchEntry}
        ></Exchange>
      </>
    ) : (
      <td>
        <Loader content="Loading Exchange Options" />
      </td>
    );
  } else {
    return null;
  }
}

export default Index;
