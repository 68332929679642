import { createSlice } from "@reduxjs/toolkit";
import { getOrder, getReturnRecord } from "../../../api/orderApi";

export const initialState = {
  value: {},
  existingReturn: {},
  over30Days: false,
  comfortGuarantee: false,
};

export const orderSlice = createSlice({
  name: "order",
  initialState: initialState,
  reducers: {
    setItems: (state, action) => {
      state.value = action.payload[0];
    },
    setExistingReturn: (state, action) => {
      state.existingReturn = action.payload;
    },
    setDate: (state, action) => {
      state.over30Days = action.payload;
    },
    setComfortGurantee: (state, action) => {
      state.comfortGuarantee = action.payload;
    },
    clearOrder: (state) => initialState,
  },
});

export const {
  setItems,
  clearOrder,
  setDate,
  setComfortGurantee,
  setExistingReturn,
} = orderSlice.actions;

export const getItems = (orderId, token, csrf) => (dispatch) => {
  return getOrder(orderId, token, csrf).then((items) => {
    return dispatch(setItems(items));
  });
};

export const getReturnRecords = (orderId, token, csrf) => (dispatch) => {
  return getReturnRecord(orderId, token, csrf).then((items) => {
    console.log("getReturnRecord", items);
    return dispatch(setExistingReturn(items));
  });
};

export const selectReturns = (state) => {
  console.log("selectReturns", state.order.existingReturn);
  return state.order && state.order.existingReturn
    ? state.order.existingReturn
    : null;
};

export const selectItems = (state) => {
  return state.order && state.order.value ? state.order.value.items : null;
};

export const selectOrder = (state) => {
  return state.order && state.order.value ? state.order.value : null;
};

export const canOnlyReturnFaulty = (state) => {
  return state.order && state.order.over30Days ? state.order.over30Days : null;
};

export const hasComfortGurantee = (state) => {
  return state.order && state.order.comfortGuarantee
    ? state.order.comfortGuarantee
    : null;
};

export default orderSlice.reducer;
