import React, { useEffect, useState } from "react";
import "./Index.css";
import SelectInput from "../shared/SelectInput";
import { selectIsExchangeable } from "../../redux/slices/customer/customerSlice";
import { useSelector } from "react-redux";

function Type({ refundType, itemId, selected, onChange, item }) {
  const [display, setDisplay] = useState(false);
  const isExchangeable = useSelector(selectIsExchangeable);

  useEffect(() => {
    if (selected[itemId]) {
      setDisplay(true);
    } else {
      setDisplay(false);
    }
  }, [selected[itemId]]);

  function getOptions() {
    let options = [
      { value: "Refund", text: "Refund" },
      // { value: "Exchange", text: "Exchange" },
    ];
    console.log("isExchangeable", isExchangeable);
    if (!isExchangeable) {
      options = [{ value: "Refund", text: "Refund" }];
    }
    return options;
  }

  return display ? (
    <>
      <td>
        <span>Do you want a return ?</span>
        <SelectInput
          name="refundType"
          onChange={(e) => onChange(e, itemId, item)}
          defaultOption="Please Select"
          value={refundType[itemId]}
          label=""
          disabled={false}
          options={getOptions()}
        />
      </td>
    </>
  ) : null;
}

export default Type;
