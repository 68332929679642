import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  title: "Create a Return",
  subTitle: "",
};

export const titleSice = createSlice({
  name: "title",
  initialState: initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setSubTitle: (state, action) => {
      state.subTitle = action.payload;
    },
    clearTitle: (state) => initialState,
  },
});

export const { setTitle, setSubTitle, clearTitle } = titleSice.actions;

export const selectTitle = (state) => {
  return state.title.title ? state.title.title : null;
};

export const selectSubTitle = (state) => {
  return state.title.subTitle ? state.title.subTitle : null;
};

export default titleSice.reducer;
