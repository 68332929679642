import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  value: 0,
};

export const stepSlice = createSlice({
  name: "step",
  initialState: initialState,
  reducers: {
    setStepStatus: (state, action) => {
      state.value = action.payload;
    },
    clearSteps: (state) => initialState,
  },
});

export const { setStepStatus, clearSteps } = stepSlice.actions;

export const selectStep = (state) => {
  return state.step.value ? state.step.value : null;
};

export default stepSlice.reducer;
