import { handleResponse, handleError } from "./apiUtils";
const api_url = process.env.REACT_APP_API_URL;

function extractData(res) {
  return res.json().then((response) => {
    if (response) {
      if (response.items) {
        return response.items;
      }
      return response;
    } else {
      return null;
    }
  });
}

export function getReasons(passKey) {
  return fetch(api_url + "/reasons/reasons", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}

export function getExtendedReasons(passKey) {
  return fetch(api_url + "/reasons/extendedReasons", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: "Bearer " + passKey,
    },
  })
    .then(handleResponse)
    .then(extractData)
    .catch(handleError);
}
