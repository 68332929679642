import React from "react";
import { Icon, IconButton } from "rsuite";
import "./CancelOrder.css";

function Next({ handleClick, content, disabled = false, color = "lightgrey" }) {
  return disabled ? (
    <IconButton
      onClick={handleClick}
      icon={<Icon icon="arrow-right" />}
      placement="right"
      disabled
      color={color}
    >
      {content}
    </IconButton>
  ) : (
    <IconButton
      onClick={handleClick}
      icon={<Icon icon="arrow-right" />}
      placement="right"
      color={color}
    >
      {content}
    </IconButton>
  );
}

export default Next;
