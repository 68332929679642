import React from "react";
import { Button } from "rsuite";
import "./CancelOrder.css";

function ConfirmOrder({ handleClick, content }) {
  return (
    <Button color="green" onClick={handleClick}>
      {content}
    </Button>
  );
}

export default ConfirmOrder;
