import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectColourOptions,
  selectSizeOptions,
  selectUsSizeOptions,
} from "../../../redux/slices/option/optionSlice";
import Colour from "./Colour";
import Size from "./Size";

function Options({
  size,
  colour,
  itemId,
  onChange,
  refundType,
  selected,
  exchangeProducts,
  setNoOptionsAvailable,
  family,
}) {
  const sizeOptions = useSelector(selectSizeOptions);
  const usSizeOptions = useSelector(selectUsSizeOptions);

  const colourOptions = useSelector(selectColourOptions);

  const [sizesOptions, setSizesOptions] = useState([]);
  const [sizesAvailable, setSizesAvailable] = useState([]);

  function getOptionLabel(optionId, option) {
    if (optionId === "color") {
      if (colourOptions) {
        // console.log("colourOptions", colourOptions);
        let result = colourOptions.find((element) => {
          return String(element.value) === String(option.value);
        });
        console.log("result", result);
        if (result && result.label) {
          return result.label;
        }
      }
    } else if (optionId === "size") {
      console.log("sizeOptions", sizeOptions);
      if (sizeOptions) {
        let result = sizeOptions.find((obj) => {
          return String(obj.value) === String(option.value);
        });
        if (result && result.label) {
          return result.label;
        }
      }
    } else if (optionId === "us_size") {
      console.log("us sizeOptions", usSizeOptions);
      if (usSizeOptions) {
        let result = usSizeOptions.find((obj) => {
          return String(obj.value) === String(option.value);
        });
        if (result && result.label) {
          return result.label;
        }
      }
    }
    return false;
  }

  const getHeader = () => {
    return family && family.toLowerCase().includes("insole") ? (
      <span>Choose New Size</span>
    ) : (
      <span>Choose New Colour / Size</span>
    );
  };

  return (
    <td>
      {getHeader()}
      <Size
        itemId={itemId}
        size={size}
        sizesOptions={sizesOptions}
        setSizesOptions={setSizesOptions}
        onChange={onChange}
        refundType={refundType}
        exchangeProducts={exchangeProducts}
        selected={selected}
        getOptionLabel={getOptionLabel}
        sizesAvailable={sizesAvailable}
        setSizesAvailable={setSizesAvailable}
        setNoOptionsAvailable={setNoOptionsAvailable}
        family={family}
      ></Size>
      <Colour
        itemId={itemId}
        colour={colour}
        sizesAvailable={sizesAvailable}
        sizesOptions={sizesOptions}
        getOptionLabel={getOptionLabel}
        size={size}
        onChange={onChange}
        exchangeProducts={exchangeProducts}
        refundType={refundType}
        selected={selected}
        setNoOptionsAvailable={setNoOptionsAvailable}
        family={family}
      ></Colour>
    </td>
  );
}

export default Options;
