import React, { useEffect, useState, useMemo } from "react";

import "./Footer.css";

function Footer() {
  const [country, setCountry] = useState("");
  const [loading, setLoading] = useState(true);
  // useMemo(() => {
  //   fetch("https://jsonip.com", { mode: "cors" })
  //     .then((resp) => resp.json())
  //     .then((data) => {
  //       console.log(data);
  //       setCountry(data.country);
  //       setLoading(false);
  //     });
  // }, []);
  useEffect(() => {
    fetch("https://ipinfo.io/json?token=98430a100d8873", { mode: "cors" })
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        setCountry(data.country);
        setLoading(false);
      });
  }, []);
  return (
    <div className="footer">
      <div class="footer-info footer-col">
        <h4>Contact</h4>
        <div class="footer-col-content">
          {loading ? null : country == "US" ? (
            <ul class="list-contacts-footer">
              <li>
                <strong>Address:</strong>
                <a href={"#"} target="_blank" rel="noopener">
                  19113 63rd Avenue Ne, Suite 4, Arlington, WA, 98223
                </a>
              </li>
              <li>
                <strong>E-mail:</strong>
                <a href="mailto:usasales@strivefootwear.com">
                  usasales@strivefootwear.com
                </a>
              </li>
              <li class="business-hours">
                <strong>Hours:</strong> Mon to Fri from 8:30 am to 5:00 pm
              </li>
            </ul>
          ) : (
            <ul class="list-contacts-footer">
              <li>
                <strong>Address:</strong>
                <a
                  href={"https://goo.gl/maps/Aj84vFQe4Jt"}
                  target="_blank"
                  rel="noopener"
                >
                  Strive Footwear, Emerald Way, Stone, Staffs, ST15 0SR
                </a>
              </li>
              <li>
                <strong>Phone:</strong>
                <a href="callto:08081782526">+44 (0)808 178 2526</a>
              </li>
              <li>
                <strong>E-mail:</strong>
                <a href="mailto:sales@strivefootwear.com">
                  sales@strivefootwear.com
                </a>
              </li>
              <li class="business-hours">
                <strong>Hours:</strong> Mon to Fri from 8:30 am to 5:00 pm
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default Footer;
