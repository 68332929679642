import React from "react";

import { Icon, IconButton } from "rsuite";
import "./CancelOrder.css";

function Back({ handleClick, content }) {
  return (
    <IconButton
      onClick={handleClick}
      icon={<Icon icon="arrow-left" />}
      placement="left"
    >
      {content}
    </IconButton>
  );
}

export default Back;
