import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "rsuite";
import { clearCustomer } from "../../../redux/slices/customer/customerSlice";
import { clearOrder } from "../../../redux/slices/order/orderSlice";
import { clearReturn } from "../../../redux/slices/return/returnSlice";
import { clearSteps } from "../../../redux/slices/step/stepSlice";
import { setTitle } from "../../../redux/slices/title/titleSlice";
import "./CancelOrder.css";
import { useHistory } from "react-router-dom";

function CancelOrder({ history, direction, content, colour }) {
  const dispatch = useDispatch();
  let hist = useHistory();

  const handleClick = () => {
    dispatch(clearCustomer());
    dispatch(clearOrder());
    dispatch(clearReturn());
    dispatch(clearSteps());
    dispatch(setTitle("Create a Return"));
    hist.push(direction);
  };

  return (
    <Button color={colour ? colour : "red"} onClick={handleClick}>
      {content}
    </Button>
  );
}

export default CancelOrder;
