import React from "react";
import { ButtonToolbar } from "rsuite";
import CancelOrder from "../shared/buttons/CancelOrder";
import Next from "../shared/buttons/Next";
import EmailInput from "../shared/EmailInput";

const EmailForm = ({ email, onChange, errors, onSave, history }) => {
  const validateEmailForm = () => {
    return email ? (
      <Next handleClick={onSave} content={"Next"} color={"green"} />
    ) : (
      <Next
        handleClick={onSave}
        content={"Enter Email Address to Continue"}
        disabled={true}
      />
    );
  };

  return (
    <>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <EmailInput
        name="email"
        label=""
        value={email}
        placeholder="Enter your email address"
        onChange={(value) => onChange(value)}
        error={errors.email}
      />
      <ButtonToolbar>
        <CancelOrder
          history={history}
          direction={"/"}
          content={"Cancel Return"}
        />
        {validateEmailForm()}
      </ButtonToolbar>
    </>
  );
};

export default EmailForm;
