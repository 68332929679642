import React from "react";
import { ButtonToolbar } from "rsuite";
import Next from "../shared/buttons/Next";
import TextInput from "../shared/TextInput";

const OrderNumberForm = ({ orderNumber, onChange, errors, onSave }) => {
  const validateOrderForm = () => {
    return orderNumber ? (
      <Next handleClick={onSave} content={"Next"} color={"green"} />
    ) : (
      <Next
        handleClick={onSave}
        content={"Enter Order Number to Continue"}
        disabled={true}
      />
    );
  };

  return (
    <>
      {errors.onSave && (
        <div className="alert alert-danger" role="alert">
          {errors.onSave}
        </div>
      )}
      <TextInput
        name="orderNumber"
        label=""
        value={orderNumber}
        placeholder="Enter your order number"
        onChange={(value) => onChange(value)}
        error={errors.orderNumber}
      />
      <ButtonToolbar>{validateOrderForm()}</ButtonToolbar>
    </>
  );
};

export default OrderNumberForm;
