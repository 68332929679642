import { createSlice } from "@reduxjs/toolkit";
import { getCustomerToken } from "../../../api/authApi";
const key = process.env.REACT_APP_SECRET_KEY_REGEGB;
export const initialState = {
  token: "",
  isMerchantAdmin: false,
};
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setMerchant: (state, action) => {
      state.isMerchantAdmin = action.payload;
    },
  },
});
export const { setToken, setMerchant } = authSlice.actions;
export const generateToken = () => (dispatch) => {
  return getCustomerToken(key).then((result) => {
    if (result && result.success) {
      dispatch(setToken(result.token));
      return result;
    } else {
      return { success: false };
    }
  });
};
export const generateMerchant = (ismerchant) => (dispatch) => {
  // console.log(ismerchant);
  dispatch(setMerchant(ismerchant));
  // return { success: true };
};
export const selectToken = (state) =>
  state.auth && state.auth.token ? state.auth.token : null;
export const selectMerchant = (state) =>
  state.auth.isMerchantAdmin ? state.auth.isMerchantAdmin : false;
export default authSlice.reducer;
