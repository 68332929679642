import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PageNotFound from "../notFound/Index.js";
import Customer from "../customer/Index.js";
import Return from "../return/Index.js";
import Confirm from "../confirm/Index.js";
import Success from "../success/Index.js";
import Merchantlogin from "../merchantLogin/Index.js";
import { selectMerchant } from "../../redux/slices/auth/authSlice";
import { useSelector } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Routes.css";

function Container({ location }) {
  const merchant = useSelector(selectMerchant);
  console.log(merchant, "merchant");
  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        timeout={{ enter: 300, exit: 300 }}
        classNames="fade"
      >
        <Switch location={location}>
          <Route exact path="/" component={Customer} />
          <Route exact path="/return" component={Return} />
          <Route exact path="/confirm" component={Confirm} />
          <Route exact path="/success" component={Success} />
          {/* {!merchant && <Route exact path="/login" component={Merchantlogin} />} */}
          <Route component={PageNotFound} />
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default withRouter(Container);
