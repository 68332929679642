import { createSlice } from "@reduxjs/toolkit";
import { getReasons, getExtendedReasons } from "../../../api/reasonApi";

export const initialState = {
  reasons: [],
  extendedReasons: [],
};

export const reasonSlice = createSlice({
  name: "reason",
  initialState: initialState,
  reducers: {
    setReasons: (state, action) => {
      state.reasons = action.payload;
    },
    setExtendedReasons: (state, action) => {
      state.extendedReasons = action.payload;
    },
    clearOrder: (state) => initialState,
  },
});

export const {
  clearOrder,
  setReasons,
  setExtendedReasons,
  setItems,
} = reasonSlice.actions;

export const loadReasons = (token) => (dispatch) => {
  getReasons(token)
    .then((reasons) => {
      dispatch(setReasons(reasons));
    })
    .then(() => {
      getExtendedReasons(token).then((extendedReasons) => {
        dispatch(setExtendedReasons(extendedReasons));
      });
    });
};

export const selectReasons = (state) => {
  return state.reason && state.reason.reasons ? state.reason.reasons : null;
};

export const selectExtendedReasons = (state) => {
  return state.reason && state.reason.extendedReasons
    ? state.reason.extendedReasons
    : null;
};

export default reasonSlice.reducer;
