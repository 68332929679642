import React from "react";
import "./Checkbox.css";

const Checkbox = ({ name, label, onChange, value, error, disabled }) => {
  let wrapperClass = "form-group";
  if (error && error.length > 0) {
    wrapperClass += " " + "has-error ";
  }

  return (
    <div className={wrapperClass + " " + name.replace(" ", "-").toLowerCase()}>
      <label htmlFor={name} className="field">
        <span>{label}</span>
        <input
          type="checkbox"
          name={name}
          className="form-control"
          defaultChecked={value}
          onChange={onChange}
          disabled={disabled}
          id={name}
        />
        <span class="checkmark"></span>
        {error && <div className="alert alert-danger">{error}</div>}
      </label>
    </div>
  );
};

export default Checkbox;
