import { createSlice } from "@reduxjs/toolkit";
import {
  loadFamilyOptions,
  loadColourOptions,
  loadSizeOptions,
  loadUsSizeOptions,
} from "../../../api/optionApi";

export const initialState = {
  family: [],
  colour: [],
};

export const optionSlice = createSlice({
  name: "option",
  initialState: initialState,
  reducers: {
    setFamilyOptions: (state, action) => {
      state.family = action.payload;
    },
    setColourOptions: (state, action) => {
      state.colour = action.payload;
    },
    setSizeOptions: (state, action) => {
      state.size = action.payload;
    },
    setUsSizeOptions: (state, action) => {
      state.usSize = action.payload;
    },
  },
});

export const {
  setFamilyOptions,
  setColourOptions,
  setSizeOptions,
  setUsSizeOptions,
} = optionSlice.actions;

export const getFamilyOptions = (token) => (dispatch) => {
  return loadFamilyOptions(token).then((options) => {
    return dispatch(setFamilyOptions(options));
  });
};

export const getColourOptions = (token) => (dispatch) => {
  return loadColourOptions(token).then((options) => {
    return dispatch(setColourOptions(options));
  });
};

export const getSizeOptions = (token) => (dispatch) => {
  return loadSizeOptions(token).then((options) => {
    return dispatch(setSizeOptions(options));
  });
};
export const getUsSizeOptions = (token) => (dispatch) => {
  return loadUsSizeOptions(token).then((options) => {
    return dispatch(setUsSizeOptions(options));
  });
};

export const selectFamilyOptions = (state) => {
  return state.option && state.option.family ? state.option.family : null;
};

export const selectColourOptions = (state) => {
  return state.option && state.option.colour ? state.option.colour : null;
};

export const selectSizeOptions = (state) => {
  return state.option && state.option.size ? state.option.size : null;
};
export const selectUsSizeOptions = (state) => {
  return state.option && state.option.usSize ? state.option.usSize : null;
};

export default optionSlice.reducer;
