import React from "react";
import "./Index.css";
import SelectInput from "../shared/SelectInput";
import { useSelector } from "react-redux";
import { selectReasons } from "../../redux/slices/reason/reasonSlice";
import ExtendedReasons from "./ExtendedReasons";
import { canOnlyReturnFaulty } from "../../redux/slices/order/orderSlice";

function Reasons({
  itemId,
  reason,
  returnType,
  onChange,
  selected,
  extendedReason,
  defaultFileList,
  setDefaultFileList,
}) {
  const reasons = useSelector(selectReasons);
  const onlyReturnFaulty = useSelector(canOnlyReturnFaulty);

  const generateLabels = () => {
    let labels = [];
    for (let reason in reasons) {
      let option;
      if (onlyReturnFaulty) {
        if (reasons[reason].label === "Faulty") {
          option = { value: reasons[reason].Id, text: reasons[reason].label };
          labels.push(option);
        }
      } else {
        option = { value: reasons[reason].Id, text: reasons[reason].label };
        labels.push(option);
      }
    }
    return labels;
  };

  return selected[itemId] &&
    Object.keys(returnType).length > 0 &&
    returnType[itemId] &&
    returnType[itemId] !== "Please Select" ? (
    <>
      <td>
        <span>What is the reason for return?</span>
        <SelectInput
          name="reasons"
          onChange={(e) => onChange(e, itemId)}
          defaultOption="Please Select"
          value={reason[itemId]}
          label=""
          disabled={false}
          options={generateLabels()}
        />
        <ExtendedReasons
          reasonId={reason[itemId]}
          itemId={itemId}
          extendedReason={extendedReason}
          onChange={onChange}
          selected={selected}
          defaultFileList={defaultFileList}
          setDefaultFileList={setDefaultFileList}
        ></ExtendedReasons>
      </td>
    </>
  ) : null;
}

export default Reasons;
